import React from 'react';

export interface EquipmentMeasurements {
  equipmentLength?: number;
  equipmentWidth?: number;
  equipmentHeight?: number;
}

export const getEquipmentMeasurements = (measurable: EquipmentMeasurements) => {
  return [
    measurable.equipmentLength,
    measurable.equipmentWidth,
    measurable.equipmentHeight
  ].filter(measurement => Boolean(measurement)).join('x');
};

interface Props {
  item: EquipmentMeasurements;
}

export const EquipmentMeasurements: React.FunctionComponent<Props> = ({item}) =>
  <span>{getEquipmentMeasurements(item)}</span>;
